<template>
  <div>
    <v-snackbar
      v-model="successSnackbar"
      :timeout="4000"
      top
      transition="scroll-y-transition"
      color="success"
    >
      Заявка на оплату счетов № {{result.elementId}} успешно создана!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="successSnackbar = false"
        >
          ОК
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      top
      :timeout="-1"
      color="error"
    >
      Ошибка ({{result}}) обратитесь к администратору!!!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="errorSnackbar = false"
        >
          ОК
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      color="error"
      v-model="isValidationError"
      top
      right
    >
      Ошибка валидации формы, проверьте поля
    </v-snackbar>
    <div class="wrapper-form">
      <form @submit.prevent>
        <form-section-title title="Информация по оплате"/>
        <div class="wrapper-block">
          <v-combobox
            v-model.trim="purpose"
            :items="purposeItems"
            ref="purpose"
            autofocus
            label="Назначение платежа"
            outlined
            dense
            :error-messages="purposeErrors"
            append-icon=""
            @blur="$v.purpose.$touch()"
          ></v-combobox>
          <v-row>
            <v-col
              class="my-0 py-0"
              md="4"
              xs="12"
            >
              <v-text-field
                v-model="money"
                v-money="money"
                ref="money"
                label="Сумма"
                outlined
                dense
                :error-messages="moneyErrors"
                @blur="$v.money.$touch()"
                suffix="руб"
                prepend-icon="mdi-cash-multiple"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="my-0 py-0"
              md="4"
              xs="12"
            >
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateText"
                    label="Требуемая дата оплаты"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  :min="new Date().toISOString().substr(0, 10)"
                  locale="ru"
                  first-day-of-week="1"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-textarea
            v-model.trim="description"
            label="Комментарий к оплате (необязательно)"
            auto-grow
            rows="3"
            outlined
            dense
          ></v-textarea>
          <v-file-input
            v-model="files"
            multiple
            id="test_file_input"
            label="Документы (необязательно)"
            counter
            outlined
            dense
            prepend-icon="mdi-paperclip"
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </div>
        <form-section-title title="Дополнительная информация"/>
        <div class="wrapper-block">
          <deal-input-sync
            v-model="deal"
            :errorMessages="dealErrors"
          />
          <ProjectInput v-model="project"/>
          <TaskInput v-model="taskId"></TaskInput>
        </div>
        <v-btn
          class="mr-4 mb-4"
          color="primary"
          @click="submit"
          type="submit"
          :loading="loading"
        >
          Оставить заявку
        </v-btn>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import { required } from 'vuelidate/lib/validators'
  import ProjectInput from './app/ProjectInput'
  import TaskInput from './app/TaskInput'
  import FormSectionTitle from '@/components/FormSectionTitle'
  import DealInputSync from '@/components/DealInputSync'

  export default {
    name: 'PaymentForm',
    components: { DealInputSync, FormSectionTitle, TaskInput, ProjectInput },
    validations: {
      purpose: { required },
      money: { required },
      deal: { required }
    },
    data: () => ({
      purpose: null,
      purposeItems: [
        'Оплата депозита',
        'Оплата Банковской гарантии',
        'Субподряд и субаренда',
        'Расходные материалы и малоценка'
      ],
      money: null,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      description: null,
      files: [],
      deal: null,
      project: null,
      taskId: '',
      result: '',
      loading: false,
      isValidationError: false,
      successSnackbar: false,
      errorSnackbar: false
    }),
    computed: {
      dateText () {
        return this.date.split('-').reverse().join('-')
      },
      purposeErrors () {
        const errors = []
        if (!this.$v.purpose.$dirty) return errors
        !this.$v.purpose.required && errors.push('Укажите назначение платежа')
        return errors
      },
      moneyErrors () {
        const errors = []
        if (!this.$v.money.$dirty) return errors
        !this.$v.money.required && errors.push('Укажите необходимую сумму')
        return errors
      },
      dealErrors () {
        const errors = []
        if (!this.$v.deal.$dirty) return errors
        !this.$v.deal.required && errors.push('Укажите сделку')
        return errors
      }
    },
    methods: {
      ...mapActions(['paymentRequest']),
      async submit () {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.isValidationError = true
          return false
        } else {
          this.$v.$reset()
          this.loading = true
          const formData = {
            purpose: this.purpose,
            date: this.date,
            money: this.money.replace(/\s/g, ''),
            description: this.description,
            files: this.files,
            deal: this.deal,
            taskId: this.taskId
          }
          const result = await this.paymentRequest(formData)
          this.loading = false
          this.result = result
          if (result.elementId) {
            this.successSnackbar = true
            this.description = this.money = this.taskId = this.purpose = null
            this.files = []
          } else {
            this.errorSnackbar = true
          }
        }
      }
    }
  }
</script>
